import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { LoginButton } from './components/LoginButton';
import ArtistChecker from './components/ArtistChecker';

function App() {
  const [token, setToken] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const parsedHash = queryString.parse(location.hash);
    if (parsedHash.access_token) {
      setToken(parsedHash.access_token);
    }
  }, [location]);

  return (
    <div className="App">
      {token ? <ArtistChecker token={token} /> : <LoginButton />}
    </div>
  );
}

export default App;
